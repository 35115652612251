import { Link } from "gatsby";
import * as React from "react";
import "./Drawer.scss";
import CloseButton from "../../images/icons/close.inline.svg";
import QavantLogo from "../../images/logos/qavant-white.inline.svg";

const Drawer = ({ closeDrawer, isDrawerOpen }) => {
  return (
    <div id="drawer" className={`drawer ${isDrawerOpen && "active"}`}>
      <CloseButton
        onClick={closeDrawer}
        role="button"
        alt="botón cerrar"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
      />
      <Link onClick={closeDrawer} to="/" aria-label="Qavant">
        <QavantLogo />
      </Link>
      <hr />
      <ul className="options">
        <li>
          <Link onClick={closeDrawer} to="/">
            Inicio
          </Link>
        </li>
        <li>
          <Link onClick={closeDrawer} to="/#solutions">
            Soluciones
          </Link>
        </li>
        <li>
          <Link onClick={closeDrawer} to="/#clients">
            Clientes
          </Link>
        </li>
        <li>
          <Link onClick={closeDrawer} to="/news">
            Novedades
          </Link>
        </li>
        <li>
          <Link onClick={closeDrawer} to="/demo">
            Demo
          </Link>
        </li>
        <li>
          <Link onClick={closeDrawer} to="/#contact">
            Contacto
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Drawer;
