import { Link } from "gatsby";
import * as React from "react";
import "./Footer.scss";
import TransparentFilledIcon01 from "../../images/icons/transparent-filled01.inline.svg";
import TransparentFilledIcon02 from "../../images/icons/transparent-filled02.inline.svg";
import TransparentFilledIcon03 from "../../images/icons/transparent-filled03.inline.svg";
import TransparentFilledIcon04 from "../../images/icons/transparent-filled04.inline.svg";
import QavantLogo from "../../images/logos/qavant-white.inline.svg";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-wrapper">
				<div className="content">
					<div className="section1">
						<QavantLogo />
						<p>
							Solicita una demo de Qavant para conocer la plataforma y descubrir
							todo el potencial para tu empresa.
						</p>
					</div>
					<div className="section2">
						<ul className="options">
							<li>
								<Link to="/">Inicio</Link>
							</li>
							<li>
								<Link to="/#solutions">Soluciones</Link>
							</li>
							<li>
								<Link to="/#clients">Clientes</Link>
							</li>
							<li>
								<Link to="/news">Novedades</Link>
							</li>
							<li>
								<Link to="/demo">Demo</Link>
							</li>
							<li>
								<Link to="/#contact">Contacto</Link>
							</li>
						</ul>
					</div>
					<div className="section3">
						<a
							className="element"
							href="https://goo.gl/maps/ukA9VFvrnkBR7a6D6"
							target="_blank">
							<TransparentFilledIcon01 />
							<span>Av de Mayo 749, Ciudad de Buenos Aires, Argentina</span>
						</a>
						<a href="tel:+541152177730" className="element">
							<TransparentFilledIcon02 />
							<span>+54 (11) 5217.7730</span>
						</a>

						<a href="mailto:hola@qavant.com" className="element">
							<TransparentFilledIcon03 />
							<span>hola@qavant.com</span>
						</a>

						<a
							href="https://www.linkedin.com/company/qavant"
							className="element"
							target="_blank"
							rel="noreferrer"
							aria-label="LinkedIn">
							<TransparentFilledIcon04 />
							<span>Qavant</span>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
