import * as React from "react";
import "./NavBar.scss";
import useWindowSize from "../../hooks/useWindowSize";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Drawer from "../Drawer/Drawer";
import { globalHistory } from "@reach/router";
import QavantLogo from "../../images/logos/qavant-white.inline.svg";
import BurgerMenu from "../../images/icons/mobile-menu-white.inline.svg";
import WhatsAppIcon from "../../images/icons/logo-whatsapp.svg";

const NavBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const { width } = useWindowSize();
  const [scrollState, setScrollState] = React.useState("top");
  const [isIndex, setIsIndex] = React.useState(
    () => globalHistory.location.pathname === "/"
  );

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        if (globalHistory.location.pathname === "/") setIsIndex(true);
        else setIsIndex(false);

        if (globalHistory.location.pathname === "/news") setIsIndex(true);
      }
    });
  }, []);

  React.useEffect(() => {
    let listener = null;
    listener = document.addEventListener("scroll", () => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 20) {
        if (scrollState !== "scrolled") {
          setScrollState("scrolled");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  const openDrawer = () => {
    document.querySelector("body").style.overflow = "hidden";
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    document.querySelector("body").style.overflow = "visible";
    setIsDrawerOpen(false);
  };

  return (
    <nav
      className={`navbar ${width < 992 ? "mobile" : "desktop"} ${
        scrollState === "scrolled" && "scrolled"
      } ${!isIndex && "not-index-page"}`}
    >
      <Drawer closeDrawer={closeDrawer} isDrawerOpen={isDrawerOpen} />
      <div className="content">
        <Link to="/" className="logo-link" aria-label="Qavant">
          <QavantLogo />
        </Link>
        <a className="logo-whatsapp" href="https://wa.me/+541137929390">
          <img src={WhatsAppIcon} className="logo" alt="logo" />
        </a>
        {width < 992 ? (
          <BurgerMenu
            className="btn-menu"
            role="button"
            onClick={openDrawer}
            onKeyDown={openDrawer}
          />
        ) : (
          <ul className="options">
            <li>
              <Link title="Inicio" to="/">
                Inicio
              </Link>
            </li>
            <li>
              <Link title="Soluciones" to="/#solutions">
                Soluciones
              </Link>
            </li>
            <li>
              <Link title="Clientes" to="/#clients">
                Clientes
              </Link>
            </li>
            <li>
              <Link title="News" to="/news">
                Novedades
              </Link>
            </li>
            <li>
              <Link title="Demo" to="/demo">
                Demo
              </Link>
            </li>
            <li>
              <Link title="Contacto" to="/#contact" className="contact">
                Contacto
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
