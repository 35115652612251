import "./src/theme/global.scss";
import * as React from "react";
import Layout from "./src/components/Layout/Layout";

export const wrapRootElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};
