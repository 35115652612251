import * as React from "react";

// Hook
export default function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = React.useState({ width: undefined, height: undefined });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const width = windowSize.width;
  const height = windowSize.height;
  const isMobile = width < 768;
  const isTablet = width >= 768 || width < 1280;
  const isDesktop = width >= 1280;

  return { width, height, isMobile, isTablet, isDesktop };
}
